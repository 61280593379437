import { ChatPlacementPosition } from '@kanbu/schema/enums';
import { cn } from '@utima/ui';
import { motion, AnimatePresence } from 'framer-motion';
import { MessageCircle } from 'lucide-react';
import { memo, type ComponentProps, useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useChatConfig } from '@/contexts/ChatConfigProvider';
import { useBoundStore } from '@/store/store';

import { Image } from '../image/Image';

type BubbleProps = {
  src: string;
  alt?: string;
} & Omit<ComponentProps<'button'>, 'type'>;

const popupVariants = {
  hidden: { opacity: 0, y: 20, scale: 0.8 },
  visible: {
    opacity: 1,
    x: 0,
    y: 0,
    scale: 1,
    transition: {
      type: 'spring',
      stiffness: 500,
      damping: 30,
      delay: 0.2,
    },
  },
  exit: {
    opacity: 0,
    x: 0,
    y: 20,
    scale: 0.8,
    transition: {
      duration: 0.2,
    },
  },
};

export const Bubble = memo(function Bubble({
  src,
  alt = 'Chatbot',
  ...restProps
}: BubbleProps) {
  const { t } = useTranslation();
  const [opened, unread] = useBoundStore(state => [state.opened, state.unread]);
  const [showPopup, setShowPopup] = useState(false);
  const { chatbotConfig, themeConfig } = useChatConfig();
  const { placement } = chatbotConfig;

  /**
   * Wait a little bit before showing the popup
   */
  useEffect(() => {
    const timer = setTimeout(() => setShowPopup(true), 500);

    return () => clearTimeout(timer);
  }, []);

  const bubbleVariants = useMemo(() => {
    let offsetX = placement?.offsetX ?? 0;
    const offsetY = -(placement?.offsetY ?? 0);

    // If position is left, offsetX should be negative
    if (placement?.position === ChatPlacementPosition.Right) {
      offsetX = -offsetX;
    }

    return {
      hidden: { scale: 0.5, y: 100, opacity: 0 },
      visible: {
        scale: 1,
        x: offsetX,
        y: offsetY,
        opacity: 1,
        transition: {
          type: 'spring',
          stiffness: 400,
          damping: 25,
          duration: 0.5,
        },
      },
      exit: {
        scale: 0.5,
        x: offsetX,
        y: offsetY + 100,
        opacity: 0,
        transition: {
          duration: 0.3,
        },
      },
    };
  }, [placement?.position, placement?.offsetX, placement?.offsetY]);

  return (
    <AnimatePresence>
      {!opened && (
        <motion.div
          className={cn(
            'fixed bottom-2 right-2 sm:bottom-6 sm:right-6',
            placement?.position === ChatPlacementPosition.Left
              ? 'left-2 right-auto sm:left-6 sm:right-auto'
              : 'right-2 left-auto sm:right-6 sm:left-auto',
          )}
          initial='hidden'
          animate='visible'
          exit='exit'
          variants={bubbleVariants}
        >
          <AnimatePresence>
            {showPopup && (
              <motion.div
                variants={popupVariants}
                initial='hidden'
                animate='visible'
                exit='exit'
                className={cn(
                  'absolute -top-4 right-12 z-10 flex w-auto flex-col gap-1 rounded-2xl bg-primary px-5 py-3 text-primary-fg shadow-md sm:right-14 ',
                  placement?.position === ChatPlacementPosition.Left
                    ? 'left-12 right-auto sm:left-14 sm:right-auto pl-6 sm:pl-8'
                    : 'right-12 left-auto sm:right-14 sm:left-auto pr-8 sm:pr-10',
                )}
              >
                <p className='whitespace-nowrap text-xs sm:text-sm'>
                  {chatbotConfig.bubbleTitle || t('bubble.initialTitle')}
                </p>
                <p className='whitespace-nowrap text-sm font-bold sm:text-base'>
                  {chatbotConfig.bubbleDescription ||
                    t('bubble.initialSubtitle')}
                </p>
              </motion.div>
            )}
          </AnimatePresence>
          <button
            className='group relative z-20 size-16 rounded-full bg-primary shadow-md sm:size-20'
            type='button'
            {...restProps}
          >
            <div
              className={cn(
                'flex size-full select-none items-center justify-center rounded-full border-2 border-white object-cover overflow-hidden transition-transform group-hover:scale-105 group-active:scale-100',
              )}
            >
              <Image
                alt={alt}
                src={themeConfig?.logoBubble ?? src}
                className='h-auto w-20'
              />
            </div>
            <div
              className={cn(
                'absolute -bottom-1 -left-1 sm:bottom-0 sm:left-0 flex size-7 sm:size-8 items-center justify-center rounded-full border-2 border-white text-sm transition-colors',
                {
                  ['bg-red text-white animate-bounce-short']: unread,
                  ['bg-secondary text-secondary-fg']: !unread,
                },
              )}
            >
              {unread || (
                <MessageCircle
                  className='size-[12px] rotate-90 -scale-100 sm:size-[14px]'
                  strokeWidth={2.5}
                />
              )}
            </div>
          </button>
        </motion.div>
      )}
    </AnimatePresence>
  );
});
